import './youtubeInText.css'
import React from 'react'
import qs from 'qs'

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return (
    text
    && text.replace(urlRegex, function(url) {
      const query = qs.parse(url, {
        ignoreQueryPrefix: true,
        // 문자열 맨 앞의 ?를 생력
      })

      if (url.indexOf('youtube') !== -1 || url.indexOf('youtu.be') !== -1) {
        // if (url.indexOf('youtube') !== -1) {
        if (query[Object.keys(query)[0]]) {
          return `<div class="youtube-in-text">
                    <iframe width="100%" height="100%"
                    src="https://www.youtube.com/embed/${
  // url.split('watch?v=')[1]
    query[Object.keys(query)[0]]
    }?rel=0&modestbranding=1"
                    frameborder="0" allow="accelerometer;
                    autoplay;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                    allowfullscreen>
                    </iframe></div>`
        }
        // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {
        if (!query[Object.keys(query)[0]]) {
          return `<div class="youtube-in-text">
                  <iframe width="100%" height="100%"
                  src="https://www.youtube.com/embed/${url
      .split('youtu.be/')[1]
      .substr(0, 11)}?rel=0&modestbranding=1"
                  frameborder="0" allow="accelerometer;
                  autoplay;
                  encrypted-media;
                  gyroscope;
                  picture-in-picture"
                  allowfullscreen>
                  </iframe></div>`
        }
      }
      return url.replace(urlRegex, '<a style="color: -webkit-link" href="$1" target="_blank">$1</a>')
    })
  )
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const YoutubeInText = ({ text }) => {
  return (
    <div
      className="youtube-in-text-wrapper"
      dangerouslySetInnerHTML={
        {
          __html: urlify(text),
        }
      }
    />
  )
}

export default YoutubeInText
