import React, { useEffect } from 'react'
import { Router, Route, Switch, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { LastLocationProvider } from 'react-router-last-location'
import InitializePage from '@utils/InitializePage'

import HomePage from './HomePage'
import RootPage from './RootPage'
import TrackPage from './TrackPage'
import AlbumPage from './AlbumPage'
import ArtistPage from './ArtistPage'
import SubdomainPage from './SubdomainPage'
import NotFoundPage from './NotFoundPage'
import SearchPage from './SearchPage'
import PrivacyPage from './PrivacyPage'
import LoginPage from './LoginPage'
import CommentPage from './CommentPage'
import InPlamArtistPage from './InPlamArtistPage'
import InPlamAddPage from './InPlamAddPage'
import InPlamArticlePage from './InPlamArticlePage'
import InplamNotFoundPage from './InplamNotFoundPage'
import ActivityArticlePage from './ActivityArticlePage'
import ActivityCommentPage from './ActivityCommentPage'
import InPlamTotalPage from './InPlamTotalPage'
import InPlamNoticePage from './InPlamNoticePage'
import EditProfilePage from './EditProfilePage'
import InPlamPage from './InPlamPage'
import MyIdolPage from './MyIdolPage'
import MyIdolResultPage from './MyIdolResultPage'
import ValuationPage from './ValuationPage'
import ArtistMelonPage from './ArtistMelonPage'
import ArtistYoutubePage from './ArtistYoutubePage'
import ArtistSnsListenDetailPage from './ArtistSnsListenDetailPage'
import TrackSnsListenDetailPage from './TrackSnsListenDetailPage'
import SecessionPage from './SecessionPage'
import AuthFindIdPage from './AuthFindIdPage'
import AuthFindPasswordPage from './AuthFindPasswordPage'
import AuthChangePasswordPage from './AuthChangePasswordPage'
import routeSubdomain from './routeSubdomain'
import AppDownloadPage from './AppDownloadPage'
import RewardSharePage from './RewardSharePage'

const SwitchRouter = ({ history }) => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Switch>
      <Route path="/auth/secession" component={SecessionPage} />
      {/* 이벤트 페이지 */}
      <Route exact path="/myidol" component={MyIdolPage} />
      <Route exact path="/myidol/:mbti" component={MyIdolResultPage} />
      <Route path="/search" component={SearchPage} />
      <Route
        exact
        path="/login/:platform"
        component={LoginPage}
        last={history}
      />
      <Route exact path="/" component={routeSubdomain(HomePage)} />
      <Route exact path="/login" component={LoginPage} />

      {/* 앱 다운로드 링크 공유 페이지 */}
      <Route exact path="/download" component={AppDownloadPage} />

      {/* 음감회 링크 공유 페이지 */}
      <Route exact path="/reward/:rewardId" component={RewardSharePage} />

      {/* 내가 쓴 글 */}
      <Route path="/activity/update" component={EditProfilePage} />
      <Route path="/activity/articles" component={ActivityArticlePage} />
      <Route path="/activity/comments" component={ActivityCommentPage} />

      {/* 댓글 페이지 */}
      <Route
        exact
        path="/comment/:dataCollectionName/:dataObjectId"
        component={CommentPage}
      />
      {/* 인플램 페이지 */}
      <Route exact path="/inplam" component={InPlamPage} />
      <Route exact path="/inplam/total" component={InPlamTotalPage} />
      <Route exact path="/inplam/notice" component={InPlamNoticePage} />
      <Route exact path="/inplam/:uniqueName" component={InPlamArtistPage} />
      <Route
        exact
        path="/inplam/add/:uniqueName/:dataObjectId"
        component={InPlamAddPage}
      />
      <Route
        exact
        path="/inplam/:uniqueName/:dataObjectId"
        component={InPlamArticlePage}
      />
      {/* 가치평가 예상 페이지 */}
      <Route exact path="/valuation" component={ValuationPage} />
      {/* 정책 페이지 */}
      <Route exact path="/privacy/:type" component={PrivacyPage} />
      {/* uniqueName(아티스트) || 서브도메인(트랙, 앨범) 페이지 */}
      <Route exact path="/:id" component={routeSubdomain(RootPage)} />
      {/* 서브도메인 리디렉션 페이지 */}
      <Route exact path="/to/:subdomain" component={SubdomainPage} />
      <Route exact path="/to/:subdomain/:id" component={SubdomainPage} />
      {/* 아티스트, 앨범, 트랙 기본 페이지 */}
      <Route exact path="/track/:id" component={TrackPage} />
      <Route exact path="/album/:id" component={AlbumPage} />
      <Route exact path="/artist/:id" component={ArtistPage} />
      <Route
        exact
        path="/artist/:id/:sns/:type"
        component={ArtistSnsListenDetailPage}
      />
      <Route exact path="/artist/:id/melon" component={ArtistMelonPage} />
      <Route exact path="/artist/:id/youtube" component={ArtistYoutubePage} />
      <Route
        exact
        path="/track/:_id/:artistId/:snsType"
        component={TrackSnsListenDetailPage}
      />
      {/* 에러 페이지 */}
      <Route path="/error/inplam/:uniqueName" component={InplamNotFoundPage} />

      <Route path="/auth/find-id" component={AuthFindIdPage} />
      <Route path="/auth/find-password" component={AuthFindPasswordPage} />
      <Route path="/auth/change-password" component={AuthChangePasswordPage} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}

export default SwitchRouter
