import { action, observable } from 'mobx'
import { Store } from '.'
import { CommentModel, ArticleModel } from './models'
import { Network } from './networks'

export default class ArticleStore {
  @observable latestActicleList
  @observable artistInfo
  @observable artistArticleList
  @observable popularArtistArticleList
  @observable nextArticleList
  @observable artistArticleListCount
  @observable artistArticleListTotalCount
  @observable inplamFavoritesInfo
  @observable contentTitle
  @observable articleDetail

  @observable myArticleList
  @observable myArticleListCount
  @observable myArticleCommentList
  @observable myArticleCommentListCount
  @observable artistInfoList

  @observable popularTotalArticleList
  @observable totalArticleList
  @observable totalArticleListCount

  @observable noticeArticleList
  @observable noticeArticleListCount

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.latestActicleList = []
    this.artistInfo = {}
    this.artistArticleList = []
    this.popularArtistArticleList = []
    this.nextArticleList = []
    this.inplamFavoritesInfo = {}
    this.artistArticleListCount = 0
    this.artistArticleListTotalCount = 0
    this.contentTitle = null
    this.articleDetail = null

    this.myArticleList = []
    this.myArticleListCount = 0
    this.myArticleCommentList = []
    this.myArticleCommentListCount = 0
    this.artistInfoList = []

    this.popularTotalArticleList = []
    this.totalArticleList = []
    this.totalArticleListCount = 0

    this.noticeArticleList = []
  }

  // 홈화면 최신 인플램 게시글 리스트
  @action.bound
  fetchlatestActicleList = async () => {
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        limit: 2,
      })

      if (!res) return

      this.latestActicleList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
    }, 'fetchlatestActicleList')
  }

  // 인플램 페이지 인기글 리스트
  @action.bound
  fetchPopularArtistArticleList = async params => {
    if (!params.uniqueName) return

    // return this.store.useLoading(async () => {
    const res = await this.network.articleNetwork.getArticles({
      uniqueName: params.uniqueName,
      userId: params.userId ? params.userId : null,
      limit: params.limit ? params.limit : 3,
      sortBy: params.sortBy ? params.sortBy : 'popularity',
      offset: params.offset ? params.offset : 0,
    })

    if (!res) return
    this.popularArtistArticleList = res.articleList
      .filter(elem => !!elem)
      .map(elem => new ArticleModel(this.store, elem))
    this.artistInfo = res.artistInfo
    this.artistArticleListCount = res.articleCount
    // })
  }

  // 인플램 게시글 리스트
  @action.bound
  fetchArtistArticleList = async params => {
    if (!params.uniqueName) return

    // return this.store.useLoading(async () => {
    const res = await this.network.articleNetwork.getArticles({
      uniqueName: params.uniqueName,
      userId: params.userId ? params.userId : null,
      limit: params.limit ? params.limit : 10,
      sortBy: params.sortBy ? params.sortBy : 'createdAt',
      writerType: params.writerType ? params.writerType : null,
      offset: params.offset ? params.offset : 0,
    })

    if (!res) return
    this.artistArticleList = res.articleList
      .filter(elem => !!elem)
      .map(elem => new ArticleModel(this.store, elem))
    this.artistInfo = res.artistInfo
    this.artistArticleListCount = res.articleCount
    this.artistArticleListTotalCount = res.articleTotalCount
    this.inplamFavoritesInfo = res.inplamFavoritesInfo
    // })
  }

  @action.bound
  createArticle = async params => {
    if (
      !this.store.authStore.currentUser._id ||
      !params.artistId ||
      !params.title ||
      !params.text
    )
      return

    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.postArticles({
        ...params,
        userId: this.store.authStore.currentUser._id,
      })

      if (!res) return

      return !!res
    })
  }

  @action.bound
  updateArticle = async (articleId, params) => {
    if (
      !this.store.authStore.currentUser._id ||
      // || !params.artistId
      !articleId ||
      !params.title ||
      !params.text
    )
      return

    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.putArticles(articleId, {
        ...params,
        userId: this.store.authStore.currentUser._id,
      })

      if (!res) return

      return !!res
    })
  }

  @action.bound
  removeArticle = async (articleId, userId) => {
    if (!userId || !articleId) return

    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.deleteArticles(articleId, {
        userId,
      })

      return !!res
    })
  }

  @action.bound
  // async updateCommentLike(updateType: 'add' | 'delete', commentId) {
  async updateArticleLike(updateType: 'add' | 'delete', article) {
    return this.store.useLoading(async () => {
      if (!updateType || !this.store.authStore.currentUser._id || !article) {
        return false
      }

      let res

      // 좋아요 추가 일때,
      if (updateType === 'add') {
        res = await this.network.articleNetwork.postArticlesLikes(
          article._id,
          this.store.authStore.currentUser._id,
        )
        if (!res) return

        // const findIndex = this.commentList.findIndex(
        //   comment => comment['_id'] === commentId,
        // )

        article.isLike = true
        article.likeCount += 1
        // this.commentList[findIndex].isLike = true
        // this.commentList[findIndex].likeCount =
        //   this.commentList[findIndex].likeCount + 1
      } else if (updateType === 'delete') {
        // 좋아요 삭제 일때,
        res = await this.network.articleNetwork.deleteArticlesLikes(
          article._id,
          this.store.authStore.currentUser._id,
        )
        if (!res) return

        // const findIndex = this.commentList.findIndex(
        //   comment => comment['_id'] === commentId,
        // )

        article.isLike = false
        article.likeCount -= 1

        // this.commentList[findIndex].isLike = false
        // this.commentList[findIndex].likeCount =
        //   this.commentList[findIndex].likeCount - 1
      }

      return !!res
    })
  }

  @action.bound
  reportComment = async params => {
    if (!this.store.authStore.currentUser._id || params.commentId) return
    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.postCommentsReports(
        // commentId,
        params.commentId,
        { userId: this.store.authStore.currentUser._id, ...params },
      )

      if (!res) return

      if (res.data && res.data.comment) {
        return 'completed'
      }
      if (res.error && res.error.type === 'Conflict') {
        return 'conflicted'
      }
    })
  }

  @action.bound
  fetchArticleDetail = async (
    articleId,
    sortBy = 'createdAt',
    listType = 'normal',
  ) => {
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticle(
        articleId,
        sortBy,
        listType,
      )
      if (!res) return
      if (res.error) {
        if (res.error.type === 'PageNotFound') return 'PageNotFound'
        return
      }
      this.articleDetail = new ArticleModel(this.store, res.article)
      this.artistInfo = res.artistInfo
      this.nextArticleList = res.nextArticleList
    })
  }

  // 나의 인플램 게시글 리스트
  @action.bound
  fetchMyArticleList = async params => {
    if (!this.store.authStore.currentUser._id) return

    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        uniqueName: params.uniqueName || '',
        userId: this.store.authStore.currentUser._id,
        writerId: this.store.authStore.currentUser._id,
        limit: params.limit ? params.limit : 10,
        sortBy: 'createdAt',
        offset: params.offset ? params.offset : 0,
        searchBy: 'articles',
      })

      if (!res) return
      this.myArticleList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
      this.myArticleListCount = res.articleCount
      this.artistInfoList = res.artistInfoList
    })
  }

  // 나의 인플램 댓글 단 게시글 리스트
  @action.bound
  fetchMyArticleCommentList = async params => {
    if (!this.store.authStore.currentUser._id) return

    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        uniqueName: params.uniqueName || '',
        userId: this.store.authStore.currentUser._id,
        writerId: this.store.authStore.currentUser._id,
        limit: params.limit ? params.limit : 10,
        sortBy: 'createdAt',
        offset: params.offset ? params.offset : 0,
        searchBy: 'comments',
      })

      if (!res) return
      this.myArticleCommentList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
      this.myArticleCommentListCount = res.articleCount
      this.artistInfoList = res.artistInfoList
    })
  }

  // 전체 인플램 게시글 리스트
  @action.bound
  fetchTotalArticleList = async params => {
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        userId:
          this.store.authStore.currentUser &&
          this.store.authStore.currentUser._id
            ? this.store.authStore.currentUser._id
            : null,
        limit: params.limit ? params.limit : 10,
        sortBy: params.sortBy ? params.sortBy : 'createdAt',
        offset: params.offset ? params.offset : 0,
        isNotice: params.isNotice,
      })

      if (!res) return
      this.totalArticleList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
      this.totalArticleListCount = res.articleCount
      this.artistInfoList = res.artistInfoList
    })
  }

  // 전체 인기 인플램 게시글 리스트
  @action.bound
  fetchPopularTotalArticleList = async params => {
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        limit: params.limit ? params.limit : 10,
        sortBy: 'popularity',
        offset: 0,
      })

      if (!res) return
      this.popularTotalArticleList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
      // this.totalArticleListCount = res.articleCount
      // this.artistInfoList = res.artistInfoList
    })
  }

  // 인플램 공지글 리스트
  @action.bound
  fetchNoticeArticleList = async params => {
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.getArticles({
        limit: params.limit ? params.limit : 10,
        offset: params.offset ? params.offset : 0,
        isNotice: true,
      })

      if (!res) return
      this.noticeArticleList = res.articleList
        .filter(elem => !!elem)
        .map(elem => new ArticleModel(this.store, elem))
      this.noticeArticleListCount = res.articleCount
      // this.artistInfoList = res.artistInfoList
    })
  }

  @action.bound
  reportArticle = async params => {
    if (!this.store.authStore.currentUser._id || !params.articleId) return
    return this.store.useLoading(async () => {
      const res = await this.network.articleNetwork.postArticlesReports(
        params.articleId,
        {
          userId: this.store.authStore.currentUser._id,
          reason: (params.reason && params.reason) || null,
        },
      )

      if (!res) return

      if (res.data && res.data.article) {
        return 'completed'
      }
      if (res.error && res.error.type === 'Conflict') {
        return 'conflicted'
      }
    })
  }
}
