import { Network } from '.'

export default class InplamNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getInplam = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/inplam`,
        'get',
        params,
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork getInplam error', error)
    }
  }

  getArticles = async params => {
    try {
      const { data } = await this.network._axiosApi(`/articles`, 'get', params)
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork getArticles error', error)
    }
  }

  getArticle = async (articleId, sortBy) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/articles/${articleId}?sortBy=${sortBy}`,
        'get',
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork getArticle error', error)
      return error.response && error.response.data
    }
  }

  postInplamFavorites = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/inplam/favorites`,
        'post',
        params,
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork postInplamFavorites error', error)
    }
  }

  deleteInplamFavorites = async favoriteId => {
    if (!favoriteId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/inplam/favorites/${favoriteId}`,
        'delete',
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork deleteInplamFavorites error', error)
    }
  }

  postOpenRequests = async artistId => {
    if (!artistId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/inplam/open/requests`,
        'post',
        {
          artistId,
        },
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork postOpenRequests error', error)
    }
  }

  putOpenRequests = async (requestsId, params) => {
    if (!requestsId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/inplam/open/requests/${requestsId}`,
        'put',
        params,
      )
      return (data && data['data']) || null
    } catch (error) {
      console.warn('InplamNetwork putOpenRequests error', error)
      return error.response.data
    }
  }
}
