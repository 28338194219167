import React from 'react'
import { Provider, Observer } from 'mobx-react'
import { createBrowserHistory } from 'history'
import { Loading } from '@components/molecules'
import { IS_DEV } from '@consts'
import { Store } from '../stores'
import {
  addATWPostMessageListener,
  getJWTFromUserAgent,
  POST_MESSAGES,
} from './postMessage'

// import ReactGA from 'react-ga'

const history = createBrowserHistory()

export default Component => {
  class InitializePage extends React.Component {
    // static getInitialProps({ req, query, params, state }) {
    //   return {
    //     initialState: Component.getInitialProps ? Component.getInitialProps({ query, params }) : null
    //   }
    // }

    constructor(props) {
      super(props)
      this.store = new Store()
      this.store.initClient()
    }

    async componentDidMount() {
      this.store.authStore.jsonWebToken = getJWTFromUserAgent({
        navigator,
        initJWT: this.store.authStore.jsonWebToken,
      })
      addATWPostMessageListener({
        navigator,
        document,
        window,
        listeners: {
          [POST_MESSAGES.ATW_CODE.LOGOUT]: () => this.store.authStore.logout(),
          [POST_MESSAGES.ATW_CODE.LOGIN]: jwt => {
            this.store.authStore.jsonWebToken = jwt
            this.store.authStore.login()
          },
          [POST_MESSAGES.ATW_CODE.HOME_FETCH]: () => {
            if (this.store.authStore.jsonWebToken) {
              this.store.authStore.login(false, false, false)
            }
            this.store.rewardStore.fetchRewardList({
              __offset: 0,
              __limit: 20,
              isExpired: false,
            })
          },
          [POST_MESSAGES.ATW_CODE.MOVE_ARTIST_INPLAM]: async artistId => {
            history.push(`/inplam/total?offset=0&sortBy=createdAt`)

            // const artistInfo = await this.store.artistStore.fetchArtistDetailById(
            //   artistId,
            // )

            // if (!artistInfo) {
            //   return
            // }

            // if (artistInfo?.isInplamOpen) {
            //   history.push(
            //     `/inplam/${artistInfo.uniqueName}?offset=0&sortBy=createdAt`,
            //   )
            // }
            // else {
            //   history.push(`/artist/${artistInfo.uniqueName}`)
            // }
          },
          [POST_MESSAGES.ATW_CODE.MOVE_ARTIST_PLAMIN]: async artistId => {
            const artistInfo = await this.store.artistStore.fetchArtistDetailById(
              artistId,
            )

            if (!artistInfo) {
              return
            }
            history.push(`/artist/${artistInfo.uniqueName}`)
          },
          [POST_MESSAGES.ATW_CODE.MOVE_TRACK]: async trackId => {
            if (!trackId) {
              return
            }
            history.push(`/track/${trackId}`)
          },
        },
      })
      if (this.store.authStore.jsonWebToken) {
        // 아티스트 자동로그인 안했을 때, 방지하기 위한 인자값 추가
        await this.store.authStore.login(false, false, false)
      }
    }

    render() {
      return (
        <Provider {...this.store} loadingStore={this.store}>
          <Observer>
            {
              () =>
                (!this.store || !this.store || this.store.isLoading) && (
                  <Loading type="spin" color="white" />
                )
            }
          </Observer>
          <Component isServer={this.props.isServer} history={history} />
        </Provider>
      )
    }
  }
  return InitializePage
}
