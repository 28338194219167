import { H6, Flex, Caption1 } from '@components/atoms'
import React, { useRef, useEffect } from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { converseCommentUnitEng } from '@utils/format'
import { useStore } from '@utils/hooks'
import { useObserver } from 'mobx-react'

const test_data = [
  {
    _id: 1,
    title: '제목1',
    viewCount: 10,
    commentCount: 9,
  },
  {
    _id: 2,
    title: '제목2',
    viewCount: 11,
    commentCount: 90,
  },
  {
    _id: 3,
    title: '제목3',
    viewCount: 50,
    commentCount: 999,
  },
  {
    _id: 4,
    title: '제목4',
    viewCount: 1,
    commentCount: 9999,
  },
  {
    _id: 5,
    title: '제목5',
    viewCount: 1,
    commentCount: 99999,
  },
  {
    _id: 6,
    title:
      '제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6제목6',
    viewCount: 1,
    commentCount: 999999,
  },
  {
    _id: 7,
    title: '제목7',
    viewCount: 1,
    commentCount: 9999999,
  },
  {
    _id: 8,
    title: '제목8',
    viewCount: 1,
    commentCount: 3,
  },
  {
    _id: 9,
    title: '제목9',
    viewCount: 1,
    commentCount: 3,
  },
]

const ListItem = ({ item, onClick }) => {
  return (
    <InPlamListItem onClick={onClick}>
      <TitleText>{item.title}</TitleText>
      <CommentCountText>
        {item.commentCount && converseCommentUnitEng(item.commentCount)}
      </CommentCountText>
    </InPlamListItem>
  )
}

const ArtistInPlamList = ({ uniqueName, artistId }) => {
  const { articleStore } = useStore()

  const history = useHistory()

  const listScrollInterval = useRef(null) // 스크롤 애니메이션 인터벌 아이디
  const listRef = useRef(null) // 스크롤 element 아이디

  // 자동 전환 스크롤 로직
  const autoScrollInPlam = () => {
    if (
      articleStore.artistArticleList &&
      articleStore.artistArticleList.length < 1
    ) {
      return
    }
    listScrollInterval.current = setInterval(() => {
      listRef &&
        listRef.current &&
        listRef.current.scrollTo({
          top: listRef.current.scrollTop + 30,
          behavior: 'smooth',
        })

      if (
        listRef.current &&
        listRef.current.scrollTop === listRef.current.scrollHeight - 90
      ) {
        listRef.current.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }, 2000)
  }

  useEffect(() => {
    articleStore.fetchArtistArticleList({
      uniqueName,
      writerType: 'artist',
      sortBy: 'createdAt',
      limit: 1,
    })
  }, [uniqueName])

  useEffect(() => {
    if (articleStore.artistArticleList) {
      autoScrollInPlam()
    }

    // clean up
    return () => {
      clearInterval(listScrollInterval.current)
    }
  }, [articleStore.artistArticleList])

  return useObserver(() => (
    <ListBox>
      <Flex
        align="center"
        justify="space-between"
        style={{
          marginBottom: articleStore?.artistArticleList?.length > 0 ? 10 : 0,
          cursor: 'pointer',
        }}
        onClick={() => {
          articleStore.artistArticleListTotalCount > 0
            ? history.push(`/inplam/${uniqueName}`)
            : history.push(`/inplam/add/${uniqueName}/${artistId}`)
        }}
      >
        <H6 type="Bold" align="left">
          {`IN PLAM (${articleStore?.artistArticleListTotalCount})`}
        </H6>
        {articleStore.artistArticleListTotalCount < 1 ? (
          <Caption1 color={colors.brown_grey} style={{ cursor: 'pointer' }}>
            첫 글 작성하기
          </Caption1>
        ) : (
          <Caption1 color={colors.brown_grey} style={{ cursor: 'pointer' }}>
            전체보기
          </Caption1>
        )}
      </Flex>
      {articleStore.artistArticleList &&
        articleStore.artistArticleList.length > 0 && (
          <InPlamList
            ref={listRef}
            onMouseEnter={() => clearInterval(listScrollInterval.current)}
            onMouseLeave={() => autoScrollInPlam()}
          >
            {articleStore.artistArticleList.map(item => (
              <ListItem
                item={item}
                key={item._id}
                onClick={() =>
                  history.push(
                    `/inplam/${uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
                  )
                }
              />
            ))}
          </InPlamList>
        )}
    </ListBox>
  ))
}

export default ArtistInPlamList

const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
`

const InPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  max-height: 90px;
  overflow: auto;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(Caption1)`
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 100%;
`

const CommentCountText = styled(Caption1)`
  font-family: NotoSansCJKkr-Regular;
  margin-left: 5px;
  min-width: 48px;
  max-width: 48px;
  padding: 3px 9px;
  border: solid 1px ${colors.light_pink};
  border-radius: 12px;
  height: 24px;
  box-sizing: border-box;
  margin-left: 7px;
  line-height: 1.35;
`
