import { Caption1, Flex, H4 } from '@components/atoms'
import React from 'react'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Observer } from 'mobx-react'
import { LikeAristItemSkeleton } from '@components/molecules'

const LikeArtistList = ({ list, isLoading = false }) => {
  return (
    <Flex type="column" style={{ gap: 16, paddingBottom: 31 }}>
      <H4 type="Bold" align="left" style={{ height: '29px' }}>
        MY ARTIST
      </H4>
      <Observer>
        {
          () => {
            return (
              <List>
                {
                  isLoading
                    ? Array(8)
                      .fill(1)
                      ?.map(item => <LikeAristItemSkeleton />)
                    : list?.map(item => (
                      <LikeAristItem item={item} key={item._id} />
                    ))
                }
              </List>
            )
          }
        }
      </Observer>
      <Flex type="column" style={{ marginLeft: -20 }}>
        <div
          style={
            {
              width: 'calc(100vw + 20px)',
              height: 1,
              backgroundColor: '#eaeaea',
            }
          }
        />
        <div
          style={
            {
              width: 'calc(100vw + 20px)',
              height: 8,
              backgroundColor: '#fafafa',
            }
          }
        />
      </Flex>
    </Flex>
  )
}

export default LikeArtistList

const List = styled(Flex)`
  overflow-x: scroll;
  gap: 10px;
  margin-left: -20px;
  padding-left: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Name = styled(Caption1)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const LikeAristItem = ({ item }) => {
  return (
    <Link to={`/artist/${item?.uniqueName}`}>
      <Flex type="column" align="center" style={{ gap: 5, width: 64 }}>
        <Flex
          style={
            {
              width: 64,
              height: 64,
              borderRadius: '50%',
              overflow: 'hidden',
            }
          }
        >
          <img
            src={STORAGE_URL + item?.image64Path}
            alt="artist"
            width={64}
            height={64}
          />
        </Flex>
        <Name>{item?.artistName || item?.uniqueName}</Name>
      </Flex>
    </Link>
  )
}
