import { Flex, H4, H6 } from '@components/atoms'
import { InplamArtistListItem } from '@components/molecules'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { move_btn_bottom_img, move_down_gray_img } from '@images/'
import { colors } from '@colors'

const TotalInplamArtistList = ({
  list,
  setOffset,
  sortBy,
  handleChangeSortBy,
  more,
}) => {
  const history = useHistory()

  return (
    <Flex type="column" style={{ marginTop: 16 }}>
      <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
        <H4 type="Bold">인플램 전체목록</H4>
        {/* <Select
          value={sortBy}
          onChange={e => handleChangeSortBy(e.target.value)}
        >
          <option value="newest">실시간</option>
          <option value="korean">이름순</option>
          <option value="popularity">인기순</option>
        </Select> */}
      </Flex>
      <List>
        {list?.map(item => (
          <InplamArtistListItem
            item={item}
            key={item._id}
            onClick={() => {
              if (item?.isInplamOpen) {
                history.push(
                  `/inplam/${item.uniqueName}?offset=0&sortBy=createdAt`,
                )
              } else {
                history.push(`/artist/${item.uniqueName}`)
              }
            }}
          />
        ))}
      </List>
      {more && (
        <Flex
          justify="center"
          align="center"
          style={{
            width: '100%',
            margin: '8px 0 0 0',
            height: 40,
            cursor: 'pointer',
            border: 'solid 1px #d4d4d4',
          }}
          onClick={() => setOffset(prevState => prevState + 1)}
        >
          <H6>더보기</H6>
          <img
            src={move_btn_bottom_img}
            style={{ marginLeft: 4, width: 16, height: 16 }}
            alt="more_img"
          />
        </Flex>
      )}
    </Flex>
  )
}

export default TotalInplamArtistList

const List = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 4px 8px 4px;
  }
  div:first-child {
    margin: 0px 4px;
  }
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-size: 12px;
  background-repeat: no-repeat;
  display: block;

  width: 50px;
  background-position-x: 100%;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`
