import React, { useEffect } from 'react'
import { BodyWrapper, Caption1, Flex, H6 } from '@components/atoms'
import { colors } from '@colors/'
import { error_img } from '@images/'


const InplamNotFoundPage = ({match, history}) => {

  useEffect(() => {
    if(match.params.uniqueName) {
      setTimeout(() => {
        history.push(`/inplam/${match.params.uniqueName}`)
      }, 2000);
    }

  },[match])

  return (
    <BodyWrapper>
      <Flex
        type="column"
        style={
          {
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
      >
        <img
          src={error_img}
          style={{ width: 40, height: 40, marginBottom: 8 }}
          alt="error_icon"
        />
        <H6 type="Regular" style={{ marginBottom: 8 }}>
          비공개 또는 삭제된 게시글 입니다
        </H6>
        <Caption1 type="Regular" color={colors.brownish_grey}>
          잠시후 아티스트 인플램 페이지로 이동합니다
        </Caption1>
      </Flex>
    </BodyWrapper>
  )
}

export default InplamNotFoundPage
