import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import CountUp from 'react-countup'

import {
  url_share_btn,
  facebook_share_btn,
  twitter_share_btn,
  plam_mbti_banner_01,
  plam_mbti_banner_02,
} from '@images/'
import { BodyWrapper, Flex } from '@components/atoms'
import { KakaoShareBtn, MbtiBtn } from '@components/molecules'
import { LinkCopiedPopup } from '@components/organisms'
import { mbtiData } from '../../resources/mbtiData'

const MyIdolResultTemplate = ({ data, randomMemberList }) => {
  const history = useHistory()
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const handleCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  return (
    <BodyWrapper style={{ padding: 0, minHeight: '100vh' }}>
      <Flex
        type="column"
        style={
          {
            height: '100%',
            alignItems: 'center',
            backgroundColor: '#FFCC00',
            padding: '0 24px',
          }
        }
      >
        <RixText size={20} style={{ margin: '40px 0 32px 0' }}>
          당신의 ‘찰떡 아이돌’은?
        </RixText>
        <Flex style={{ position: 'relative' }}>
          <RateBox>
            <RixText size={12} style={{ marginBottom: 4 }}>
              전체
            </RixText>
            <RixText size={20}>
              <CountUp start={100} end={data.rate} duration={3} />
              {`%`}
            </RixText>
          </RateBox>
          <img
            src={randomMemberList[0]?.imagePath}
            style={
              {
                width: 200,
                height: 200,
                border: 'solid 2px #ffffff',
                borderRadius: 160,
                objectFit: 'cover',
              }
            }
            alt="member_img"
          />
        </Flex>
        <RixText size={24} style={{ margin: '24px 0' }}>
          {randomMemberList[0]?.name}
        </RixText>
        <Flex type="column">
          {
            data.description.map(item => (
              <GGText
                key={item}
                align="left"
                type="Medium"
                style={{ lineHeight: 1.57 }}
              >
                {item}
              </GGText>
            ))
          }
        </Flex>
        <RixText size={20} style={{ margin: '48px 0 20px 0' }}>
          결과 공유하기
        </RixText>
        <ShareBox>
          <div>
            <KakaoShareBtn />
            <div>
              <FacebookShareButton url={window.location.href}>
                <ShareImg src={facebook_share_btn} />
              </FacebookShareButton>
            </div>
          </div>
          <div>
            <div>
              <TwitterShareButton url={window.location.href}>
                <ShareImg src={twitter_share_btn} />
              </TwitterShareButton>
            </div>
            <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
              <div style={{ cursor: 'pointer' }}>
                <ShareImg src={url_share_btn} />
              </div>
            </CopyToClipboard>
          </div>
        </ShareBox>
        <MbtiBtn
          text="다시 하기"
          style={{ width: '90%' }}
          onClick={() => history.push('/myidol')}
        />
        <RixText size={20} style={{ margin: '48px 0 20px 0' }}>
          이 멤버도 당신과 찰떡!
        </RixText>
        <Flex style={{ width: '100%' }} justify="space-between">
          <Flex type="column">
            <img
              src={randomMemberList[1]?.imagePath}
              style={
                {
                  width: 144,
                  height: 144,
                  border: 'solid 2px #ffffff',
                  borderRadius: 72,
                  objectFit: 'cover',
                }
              }
              alt="member_img"
            />
            <RixText size={16} style={{ marginTop: '18px' }}>
              {randomMemberList[1]?.name}
            </RixText>
          </Flex>
          <Flex type="column">
            <img
              src={randomMemberList[2]?.imagePath}
              style={
                {
                  width: 144,
                  height: 144,
                  border: 'solid 2px #ffffff',
                  borderRadius: 72,
                  objectFit: 'cover',
                }
              }
              alt="member_img"
            />
            <RixText size={16} style={{ marginTop: '18px' }}>
              {randomMemberList[2]?.name}
            </RixText>
          </Flex>
        </Flex>
        <RixText size={20} style={{ margin: '42px 0 32px 0', width: '100%' }}>
          이 멤버들과
          <br />
          궁합이 잘 맞는다네요!
        </RixText>
        <BestList>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[0]].member[0].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[0]].member[0].imagePath}
            />
          </a>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[0]].member[1].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[0]].member[1].imagePath}
            />
          </a>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[0]].member[2].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[0]].member[2].imagePath}
            />
          </a>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[1]].member[0].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[1]].member[0].imagePath}
            />
          </a>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[1]].member[1].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[1]].member[1].imagePath}
            />
          </a>
          <a
            href={
              `https://plam.in/artist/${
                mbtiData[data.best_partner[1]].member[2].uniqueName
              }`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BestListImg
              src={mbtiData[data.best_partner[1]].member[2].imagePath}
            />
          </a>
        </BestList>
        <RixText size={14} style={{ margin: '32px 0 40px 0' }}>
          각 멤버의 정보가 궁금하다면?
          <br />
          프로필을 클릭해보세요!
        </RixText>
        <div
          style={
            {
              width: 'calc(100% + 48px)',
              display: 'flex',
              flexDirection: 'column',
            }
          }
        >
          <BannerLink href="https://plam.in/inplam/notice/60472d26c8697400085bb944">
            <img src={plam_mbti_banner_01} alt="banner1" />
          </BannerLink>
          <BannerLink href="https://plam.kr">
            <img src={plam_mbti_banner_02} alt="banner2" />
          </BannerLink>
        </div>
      </Flex>
      {isCopied && <LinkCopiedPopup />}
    </BodyWrapper>
  )
}

export default MyIdolResultTemplate

const RateBox = styled(Flex)`
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -20px;
`

const RixText = styled.p`
  font-family: RixYeoljeongdoPR;
  font-style: italic;
  /* transform: skew(347deg); */
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const GGText = styled.p`
  font-family: ${({ type }) =>
    type ? `GongGothicOTF${type}` : 'GongGothicOTFLight'};
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const BestList = styled(Flex)`
  justify-content: space-around;
  flex-wrap: wrap;
  width: 270px;
  height: 170px;
`

const ShareImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  object-fit: cover;
`

const BestListImg = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 36px;
  border: 2px solid #ffffff;
  object-fit: cover;
  cursor: pointer;
`

const ShareBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;

  > div {
    display: flex;
  }

  div + div {
    margin-left: 32px;
  }
`

const BannerLink = styled.a`
  width: 100%;
  display: flex;
  align-items: flex-end;

  img {
    width: 100%;
    object-fit: contain;
  }
`
