import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { converseCommentUnitEng, dateTodayYMDMM } from '@utils/format'
import { auth_artist_img, upload_photo_img } from '@images/'
import { STORAGE_URL } from '@consts'

const InPlamListItem = ({ item, artistInfo, onClick }) => {
  if (item) {
    return (
      <ItemBox onClick={() => onClick(item._id)}>
        {
          item.imageOriginalPathList && item.imageOriginalPathList.length > 0 && (
            <ThumbnailBox>
              <img
                src={
                  STORAGE_URL
                  + (item.image480PathList && item.image480PathList[0])
                }
                onError={
                  e => {
                    e.target.src = upload_photo_img
                  }
                }
                alt="exist_img"
                style={
                  {
                    width: '48px',
                    height: '48px',
                    objectFit: 'cover',
                  }
                }
              />
            </ThumbnailBox>
          )
        }
        <Flex
          type="column"
          style={
            {
              flex: 1,
              width: 1,
              marginRight: 8,
            }
          }
        >
          <Flex align="center">
            {/* {
              artistInfo && item.writerId === artistInfo.userId && (
                <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
              )
            } */}
            <TitleText>{item.title}</TitleText>
          </Flex>
          <Flex style={{ marginTop: 8 }} align="center">
            <Caption1 type="Regular" color={colors.brown_grey}>
              {/* {dateTodayYMDMM(item.createdAt)} */}
              {
                item.isAnonymous
                  ? '익명'
                  : item.writerInfo
                    && artistInfo
                    && item.writerId === artistInfo.userId
                    ? artistInfo.name
                    : item.writerInfo?.nickname
              }
            </Caption1>
            {
              !item.isAnonymous
              && artistInfo
              && item.writerId === artistInfo.userId && (
                <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
              )
            }
            <Caption1
              type="Regular"
              color={colors.brown_grey}
              style={{ margin: '0 8px' }}
            >
              조회
              {' '}
              {(item.viewCount && converseCommentUnitEng(item.viewCount)) || 0}
            </Caption1>
            <Caption1 type="Regular" color={colors.brown_grey}>
              추천
              {' '}
              {(item.likeCount && converseCommentUnitEng(item.likeCount)) || 0}
            </Caption1>
          </Flex>
        </Flex>

        <CommentCountBox>
          <Caption1 color={colors.brown_grey} type="Regular">
            댓글
          </Caption1>
          <Caption1 type="Regular">
            {
              (item.commentCount && converseCommentUnitEng(item.commentCount))
              || 0
            }
          </Caption1>
        </CommentCountBox>
      </ItemBox>
    )
  }
  return <></>
}

export default InPlamListItem

const ItemBox = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 16px 20px;
  border-bottom: solid 1px ${colors.whitef4};
  box-sizing: border-box;
  height: 76px;
  cursor: pointer;
`

const TitleText = styled(Caption1)`
  width: fit-content;
  max-width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const CommentCountBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 6px 9px;
  background-color: ${colors.whitef4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const ThumbnailBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
`

const FromArtistImg = styled.img`
  width: 12px;
  height: 12px;
`
