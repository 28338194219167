import { Flex, H6 } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { share_img_black } from '@images/'
import { useStore } from '@utils/hooks'
import { colors } from '@colors/'
import { DOMAIN_URL } from '@consts/'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSnackBar, useTooltip } from '..'
import OpenInplamPopup from '../Popup/OpenInplamPopup'

const OpenInplamCard = ({
  artistId,
  inplamOpenRequest,
  subdomainInfo,
  currentPath,
  needAuth,
}) => {
  const { authStore, artistStore, inplamStore } = useStore()

  const { View: CopiedView, toggleSnackBar: handleCopied } = useSnackBar()

  const { View: LeftView, toggleTooltip: leftToggle } = useTooltip()
  const { View: RightView, toggleTooltip: rightToggle } = useTooltip()

  const {
    isShowing: openPopupIsShowing,
    toggle: openPopupToggle,
  } = OpenInplamPopup.usePopup()

  const [accountedInfo, setAccountedInfo] = useState(null)
  const [localInplamOpenRequest, setLocalInplamOpenRequest] = useState(false)

  const handleAccountOpen = async () => {
    if (!authStore.currentUser) {
      needAuth()
      return
    }
    const accounted = await inplamStore.accountOpenRequests(artistId)
    if (!accounted) return

    setLocalInplamOpenRequest(true)
    setAccountedInfo(accounted)

    openPopupToggle()
  }

  const updatedOpen = async phone => {
    const res = await inplamStore.updateOpenRequests(
      accountedInfo._id,
      artistId,
      phone,
    )

    return res
  }

  useEffect(() => {
    if (!artistStore.isReadedOpenInplam) leftToggle()
    if (artistStore.isReadedOpenInplam && !artistStore.isReadedShareInplam)
      rightToggle()
  }, [artistStore.isReadedOpenInplam])

  return (
    <Flex
      justify="space-between"
      style={{ marginTop: 14, position: 'relative' }}
    >
      <OpenInplamPopup.View
        isShowing={openPopupIsShowing}
        toggle={openPopupToggle}
        updatedOpen={updatedOpen}
      />
      {
        localInplamOpenRequest || inplamOpenRequest ? (
          <Requested>
            <H6 color={colors.brown_grey}>오픈 요청 완료</H6>
          </Requested>
        ) : (
          <RequestOpenBtn onClick={handleAccountOpen}>
            <H6>인플램 오픈 요청</H6>
          </RequestOpenBtn>
        )
      }
      <CopyToClipboard
        text={
          subdomainInfo && subdomainInfo.subdomain && subdomainInfo.isAccepted
            ? `${subdomainInfo.subdomain}.${DOMAIN_URL}`
            : currentPath
        }
        onCopy={() => handleCopied()}
      >
        <ShareBtn>
          <img src={share_img_black} alt="share_icon" />
        </ShareBtn>
      </CopyToClipboard>
      <LeftView
        text={`아티스트 전용 커뮤니티 인플램 오픈을 요청해주세요\n요청이 일정 수 이상 도달할 시 인플램이 오픈됩니다`}
        handleClose={
          () => {
            artistStore.setIsReadedOpenInplam(true)
          }
        }
      />
      <RightView
        position={'right'}
        text={`인플램 오픈 요청을 공유하세요\n더욱 빠르게 오픈 요청 수가 달성됩니다`}
        handleClose={() => artistStore.setIsReadedShareInplam(true)}
      />
      <CopiedView
        text="주소가 클립보드에 복사되었습니다."
        backgroundColor="#66bc6a"
      />
    </Flex>
  )
}

export default OpenInplamCard

const RequestOpenBtn = styled(Flex)`
  width: 100%;
  margin-right: 8px;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  cursor: pointer;
`

const Requested = styled(Flex)`
  width: 100%;
  margin-right: 8px;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  border-radius: 6px;
  background-color: #f4f4f4;
`

const ShareBtn = styled(Flex)`
  min-width: 48px;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #f4f4f4;
  background-color: #ffffff;
  /* box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16); */
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`
