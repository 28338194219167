import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamNoticeTemplate } from '@components/templates'
import qs from 'qs'

interface InPlamNoticePageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamNoticePage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
}: InPlamNoticePageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  /* store */
  const fetchNoticeArticleList =
    articleStore.fetchNoticeArticleList || (() => {})

  const currentUser = authStore.currentUser || null
  const noticeArticleList = articleStore.noticeArticleList || null
  const noticeArticleListCount = articleStore.noticeArticleListCount || null
  /* store end */

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)

  const handlePagination = _offset => {
    history.push(`/inplam/notice?offset=${_offset}`)
  }

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
  }, [query.offset])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  useEffect(() => {
    fetchNoticeArticleList({
      limit: 10,
      offset,
    })
  }, [offset])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InPlamNoticeTemplate
      uniqueName={match.params.uniqueName}
      noticeArticleList={noticeArticleList}
      noticeArticleListCount={noticeArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
    />
  )
}

export default inject('authStore', 'articleStore')(observer(InPlamNoticePage))
