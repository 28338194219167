import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper, H6 } from '@components/atoms'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import { BurgerMenu, TotalInplamHeadList } from '@components/organisms'
import { colors } from '@colors/'
import { useObserver } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Pagination from 'react-js-pagination'
import { TotalInPlamListItem } from '@components/molecules'

interface InPlamNoticeTemplateProps {
  currentUser: any;
  isLike: boolean;
  uniqueName: string;
  artistInfo: object;
  offset: Number;
  setOffset: Function;
  sortBy: string;
  setSortBy: Function;
  isFromArtist: string;
  toggleFromArtist: Function;
  totalArticleList: Array<any>;
  popularTotalArticleList: Array<any>;
  noticeArticleListCount: Number;
  createComment: Function;
  removeComment: Function;
  _fetchCommentList: Function;
  updateCommentLike: Function;
  updateLike: Function;
  showLoginPopup: boolean;
  setShowLoginPopup: Function;
  currentPath: any;
}

const InPlamNoticeTemplate = ({
  offset,
  handlePagination,
  noticeArticleList,
  noticeArticleListCount,
}: InPlamNoticeTemplateProps) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const handleMoveDetail = id => {
    if (!id) return
    history.push({
      pathname: `/inplam/notice/${id}`,
      search: `?offset=${offset}&sortBy=createdAt`,
      state: { fromActivity: 'all' },
    })
  }

  return useObserver(() => (
    <>
      <Helmet>
        <title>{`인플램 공지사항`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 인플램"
        />
      </Helmet>
      <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pageWrapId={'comment_template'}
        outerContainerId={'App'}
        pageTitle="공지사항"
        pageId="notice"
        pageType="inplam"
      />
      <BodyWrapper
        id="comment_template"
        style={
          {
            paddingTop: 56,
            paddingBottom: 64,
            minHeight: '100vh',
          }
        }
      >
        <Flex type="column">
          {
            noticeArticleList
            && noticeArticleList.map(item => (
              <TotalInPlamListItem
                item={item}
                key={item._id}
                onClick={handleMoveDetail}
              />
            ))
          }
          <Pagination
            activePage={offset + 1}
            itemsCountPerPage={10}
            totalItemsCount={noticeArticleListCount || 0}
            pageRangeDisplayed={5}
            onChange={
              page => {
                handlePagination(page - 1)
              }
            }
            innerClass="pagination-ul"
            activeClass="current-page"
            itemClass="pagination-li"
            // hideFirstLastPages
            hideNavigation
            // prevPageText={false}
            // nextPageText={false}
            firstPageText={
              <PrevNextPage
                src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
              />
            }
            lastPageText={
              <PrevNextPage
                src={
                  offset + 1 !== Math.ceil(noticeArticleListCount / 10)
                    ? last_page_img
                    : no_last_page_img
                }
              />
            }
          />
        </Flex>
      </BodyWrapper>
    </>
  ))
}

export default InPlamNoticeTemplate

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 52px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 38px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 50px;
  display: block;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
