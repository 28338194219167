import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H5, H6, Caption1, Caption2, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { open_alarm_talk_img, check_empty_img, check_img_blue } from '@images/'
import { colors } from '@colors/'
import { useSnackBar } from '..'

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onCancel, updatedOpen }) => {
  const [isCheck, setIsCheck] = useState(false)
  const [phoneVal, setPhoneVal] = useState('')
  const { toggleSnackBar, View: SnackBar } = useSnackBar()
  const {
    View: VallidationView,
    toggleSnackBar: handleVallidation,
  } = useSnackBar()

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = async () => {
    if (!isCheck) {
      toggleSnackBar()
      return
    }
    if (updatedOpen) {
      const res = await updatedOpen(phoneVal)
      if (res === 'DUPLICATED_PHONE_NUMBER') {
        handleVallidation()
        return
      }
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <SnackBar
          text={'개인정보 수집 이용에 동의해주세요'}
          backgroundColor="#ea4653"
        />
        <VallidationView
          text="이미 알림 신청이 완료된 번호입니다."
          backgroundColor="#ea4653"
        />
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H5 style={{ marginTop: 12 }}>오픈 알림톡</H5>
              <img
                src={open_alarm_talk_img}
                alt="open_icon"
                style={
                  {
                    width: 48,
                    height: 48,
                    margin: '8px 0px 32px 0px',
                    alignSelf: 'center',
                  }
                }
              />
              <H6 align="left">
                  해당 아티스트 인플램 오픈이 요청되었습니다.
              </H6>
              <Flex type="column" style={{ margin: '8px 0 16px 0' }}>
                <Caption1
                  type="Regular"
                  align="left"
                  color={colors.brownish_grey}
                >
                    휴대폰 번호를 입력하시면 인플램 오픈 시
                </Caption1>
                <Caption1
                  type="Regular"
                  align="left"
                  color={colors.brownish_grey}
                >
                    카카오톡으로 안내 메시지를 보내드립니다.
                </Caption1>
              </Flex>
              <PhoneInput
                placeholder="‘-‘ 없이 입력해주세요"
                type="number"
                style={{ marginBottom: 16 }}
                value={phoneVal}
                onChange={e => setPhoneVal(e.target.value)}
              />
              <Flex
                onClick={() => setIsCheck(!isCheck)}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={isCheck ? check_img_blue : check_empty_img}
                  alt="checkbox_icon"
                  style={{ width: 20, height: 20, marginRight: 4 }}
                />
                <Caption1>(필수) 개인정보 수집 이용에 동의합니다.</Caption1>
              </Flex>

              <Caption2 align="left" style={{ margin: '18px 0 2px 0' }}>
                  개인정보 수집 이용 동의
              </Caption2>
              <Caption2 align="left" type="Regular" color={colors.brown_grey}>
                  플램은 이용자가 신청하신 서비스의 오픈 알림을 위해
                <br />
                  ‘휴대폰 번호’를 수집 이용하며, 오픈 알림 후 파기됩니다.
                <br />
                  개인정보 수집 이용 동의를 거부하실 수 있으며, 거부 시
                <br />
                  인플램 오픈 알림이 어렵습니다.
              </Caption2>
              {/* footer */}
              <Flex style={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
                <Okbtn onClick={handleOk}>
                  <H6>알림 신청</H6>
                </Okbtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background: white;
  overflow: auto;
  max-width: 380px;
  min-width: 280px;
  width: calc(100% - 40px);
  min-height: 474px;
  height: fit-content;
  top: 40%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const PhoneInput = styled.input`
  width: calc(100% - 12px);
  height: 48px;
  font-size: 16px;
  font-family: NotoSansCJKkr-Regular;
  border: solid 1px #d4d4d4;
  padding-left: 12px;
  margin: 0px;
`

const CancelBtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  color: #646464;
  cursor: pointer;
  margin-right: 24px;
`

const Okbtn = styled.button`
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #242424;
`
