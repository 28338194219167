import { observable } from 'mobx'
import { STORAGE_URL } from '@consts/'
import { ArtistModel } from '.'

export default class UserModel {
  /* Database fields */
  @observable _id

  @observable account
  @observable email
  @observable isAuthEmail
  @observable password
  @observable birth
  @observable sex
  @observable phone
  @observable isAuthPhone

  @observable createdAt
  @observable updatedAt
  @observable deleteAt

  @observable socialType
  @observable name
  @observable nickname
  @observable currentPoint
  @observable isInplamNotification
  @observable isArticlesNotifications
  @observable isCommentsNotifications

  @observable referralCode
  @observable appliedReferralCode
  @observable referUserId

  @observable profileImageOriginalPath = null
  @observable profileImage64Path = null
  @observable profileImage128Path = null
  @observable profileImage512Path = null
  /* Database fields end */
  get imageUri() {
    if (this.profileImageOriginalPath) {
      return `${STORAGE_URL}${this.profileImageOriginalPath}`
    }
    return null
  }
  get image512Uri() {
    if (this.profileImage512Path) {
      return `${STORAGE_URL}${this.profileImage512Path
        || this.profileImageOriginalPath}`
    }
    return null
  }
  get image128Uri() {
    if (this.profileImage128Path) {
      return `${STORAGE_URL}${this.profileImage128Path
        || this.profileImageOriginalPath}`
    }
    return null
  }
  get image64Uri() {
    if (this.profileImage64Path) {
      return `${STORAGE_URL}${this.profileImage64Path
        || this.profileImageOriginalPath}`
    }
    return null
  }
  get audioUri() {
    if (this.audioOriginalPath) {
      return `${STORAGE_URL}${this.audioOriginalPath}`
    }
    return null
  }

  @observable nowArtistCrawling
  @observable artistId
  @observable melonArtistId
  @observable inplamFavorites

  @observable type
  @observable artistInfo

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.account = props.account
      this.email = props.email
      this.isAuthEmail = props.isAuthEmail
      this.password = props.password
      this.birth = props.birth
      this.sex = props.sex
      this.phone = props.phone
      this.isAuthPhone = props.isAuthPhone

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deleteAt = props.deleteAt

      this.socialType = props.socialType
      this.name = props.name
      this.nickname = props.nickname
      this.currentPoint = props.currentPoint
      this.isInplamNotification = props.isInplamNotification
      this.isArticlesNotifications = props.isArticlesNotifications
      this.isCommentsNotifications = props.isCommentsNotifications

      this.artistId = props.artistId
      this.nowArtistCrawling = props.nowArtistCrawling
      this.melonArtistId = props.melonArtistId
      this.inplamFavorites = props.inplamFavorites

      this.referralCode = props.referralCode
      this.appliedReferralCode = props.appliedReferralCode
      this.referUserId = props.referUserId

      this.profileImageOriginalPath = props.profileImageOriginalPath
      this.profileImage512Path = props.profileImage512Path
      this.profileImage128Path = props.profileImage128Path
      this.profileImage64Path = props.profileImage64Path

      this.type = props.type
      this.artistInfo = new ArtistModel(stores, props.artistInfo)
    }
  }
}
