import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamTotalTemplate } from '@components/templates'
import qs from 'qs'

interface InPlamTotalPageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamTotalPage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
}: InPlamTotalPageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const [activeTab, setActiveTab] = useState('total')

  /* store */
  const fetchTotalArticleList = articleStore.fetchTotalArticleList || (() => {})

  const currentUser = authStore.currentUser || null
  const artistInfo = articleStore.artistInfo || null
  const totalArticleList = articleStore.totalArticleList || null
  const totalArticleListCount = articleStore.totalArticleListCount || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  /* store end */

  const [offset, setOffset] = useState(query.offset ? Number(query.offset) : 0)
  const [sortBy, setSortBy] = useState(
    query.sortBy ? query.sortBy : 'createdAt',
  )

  const handlePagination = _offset => {
    history.push(`/inplam/total?offset=${_offset}&sortBy=${sortBy}`)
  }

  const handleChangeSortBy = _sortBy => {
    history.push(`/inplam/total?offset=${offset}&sortBy=${_sortBy}`)
  }

  useEffect(() => {
    if (query.offset) setOffset(Number(query.offset))
    if (query.sortBy) setSortBy(query.sortBy)
  }, [query.offset, query.sortBy])

  useEffect(() => {
    fetchTotalArticleList({
      limit: 10,
      sortBy,
      offset,
      isNotice: activeTab === 'notice',
    })
  }, [offset, sortBy, activeTab])

  useEffect(() => {
    fetchTotalInplamList({
      sortBy: 'newest',
      offset: 0,
      limit: 20,
      sort: -1,
    })

    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InPlamTotalTemplate
      uniqueName={match.params.uniqueName}
      artistInfo={artistInfo}
      totalArticleList={totalArticleList}
      totalArticleListCount={totalArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      totalInplamList={totalInplamList}
    />
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
)(observer(InPlamTotalPage))
