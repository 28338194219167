import { ConfirmPopup, OkCancelPopup } from '@components/organisms'
import { InPlamAddTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer, useAsObservableSource } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { colors } from '@colors/'

const InPlamAddPage = ({ location, match, history }) => {
  const { articleStore, artistStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const updateLike = authStore.updateLike || (() => {})

  const [isChange, setIsChange] = useState(false) // 수정 한게 있는지
  const [showLoginPopup, setShowLoginPopup] = useState(false) // 로그인 요구 팝업
  const [artistInfo, setArtistInfo] = useState(null) // 아티스트 정보
  const [isUploading, setIsUploading] = useState(false)

  // 게시글 파일 리스트
  const [fileList, setFileList] = useState(
    location.state
      && location.state.isUpdate
      && articleStore.articleDetail
      && articleStore.articleDetail.imageOriginalPathList
      ? articleStore.articleDetail.imageOriginalPathList
      : [],
  )

  // 게시글 제목
  const [title, setTitle] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.title
      : '',
  )

  // 게시글 내용
  const [content, setContent] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.text
      : '',
  )

  // 익명 여부
  const [isAnonymous, setIsAnonymous] = useState(
    location.state && location.state.isUpdate && articleStore.articleDetail
      ? articleStore.articleDetail.isAnonymous
      : true,
  )

  const {
    isShowing: cancelShowing,
    toggle: toggleCancel,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: likeShowing,
    toggle: likeToggle,
  } = OkCancelPopup.usePopup()
  const {
    isShowing: errorShowing,
    toggle: errorToggle,
  } = ConfirmPopup.usePopup()

  // 로그인 체크
  const checkAuth = e => {
    if (!authStore.currentUser) {
      setShowLoginPopup(true)
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      return false
    }
  }

  const handleAnonymous = value => {
    if (!isChange) setIsChange(true)
    setIsAnonymous(value)
  }

  // 좋아요 누르고 바로 글 등록(수정)
  const handleLikeWrite = async () => {
    await updateLike(
      'add',
      currentUser._id,
      match.params.dataObjectId,
      'artist',
    )
    await _createArticle()
  }

  // 글 등록(수정) 함수
  const _createArticle = async () => {
    if (title === '' || content === '' || isUploading) return

    // 로그인 체크
    checkAuth()
    // 좋아요 체크
    if (authStore.likeInfo && !authStore.likeInfo.isLike) {
      likeToggle()
      return
    }

    // 등록
    if (fileList.length > 9) {
      errorToggle()
      return
    }

    // 수정
    if (location.state && location.state.isUpdate) {
      if (!isChange) return
      const created = await articleStore.updateArticle(
        articleStore.articleDetail._id,
        {
          imageOriginalPathList:
            fileList && fileList.length > 0 ? fileList : null,
          title,
          text: content,
          isAnonymous,
          // artistId: match.params.dataObjectId,
        },
      )
      if (!created) return
    }

    // 생성
    else {
      const created = await articleStore.createArticle({
        imageOriginalPathList:
          fileList && fileList.length > 0 ? fileList : null,
        title,
        text: content,
        artistId: match.params.dataObjectId,
        isAnonymous,
      })
      if (!created) return
    }

    history.push(`/inplam/${match.params.uniqueName}`)
  }

  // 수정 사항이 있을 경우, setIsChange
  useEffect(() => {
    if (
      location.state
      && location.state.isUpdate
      && articleStore.articleDetail
    ) {
      if (
        articleStore.articleDetail.title !== title
        || articleStore.articleDetail.text !== content
        || (articleStore.articleDetail.imageOriginalPathList
          && articleStore.articleDetail.imageOriginalPathList !== fileList)
        || isUploading
      ) {
        setIsChange(true)
      }
    }
  }, [location, title, content, fileList, isUploading])

  // 헤더에 사용될 아티스트 정보
  useEffect(() => {
    let res
    const fetchArtistInfo = async () => {
      res = await artistStore.fetchArtistDetailById(match.params.dataObjectId)
      setArtistInfo(res)
    }
    fetchArtistInfo()
  }, [])

  // 좋아요 체크
  useEffect(() => {
    if (authStore.currentUser && authStore.currentUser._id) {
      authStore.fetchLike(match.params.dataObjectId, authStore.currentUser._id)
    }
  }, [authStore.currentUser])

  return (
    <>
      <InPlamAddTemplate
        fileList={fileList}
        setFileList={setFileList}
        title={title}
        setTitle={setTitle}
        content={content}
        setContent={setContent}
        _createArticle={_createArticle}
        toggleCancel={toggleCancel}
        artistInfo={artistInfo}
        isUpdate={(location.state && location.state.isUpdate) || false}
        isChange={isChange}
        showLoginPopup={showLoginPopup}
        setShowLoginPopup={setShowLoginPopup}
        checkAuth={checkAuth}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        isAnonymous={isAnonymous}
        handleAnonymous={handleAnonymous}
      />
      <OkCancelPopup.View
        isShowing={cancelShowing}
        toggle={toggleCancel}
        onOk={() => history.push(`/inplam/${match.params.uniqueName}`)}
        title={
          location.state && location.state.isUpdate
            ? `수정 중인 글이 취소되며,\n 수정된 내용은 저장되지 않습니다`
            : `작성 중인 글이 취소되며,\n 작성된 글은 저장되지 않습니다`
        }
      />
      <OkCancelPopup.View
        isShowing={likeShowing}
        toggle={likeToggle}
        title={'좋아요를 누르면 글이 등록됩니다'}
        okText="좋아요"
        okColor={colors.primary}
        onOk={handleLikeWrite}
      />
      <ConfirmPopup.View
        isShowing={errorShowing}
        toggle={errorToggle}
        title={'한 게시글 당 이미지 첨부는 9장까지 가능합니다'}
        confirmColor={colors.primary}
      />
    </>
  )
}

export default observer(InPlamAddPage)
